export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('deviceCategoryName', '设备类别').setWidth(100).setSpan({}),
        BaseTableColumn.initData('portCategoryName', '端口类别').setWidth(100).setSpan({}),
        BaseTableColumn.initData('deviceModel', '设备型号', 120).setKeyValueList([
          { label: '编码:', labelWidth: 60, key: 'deviceModel', attr: { canTooltip: true } },
          { label: '名称:', labelWidth: 60, key: 'deviceTypeName', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('iotInfo', '物联网').setSpan({}),
        BaseTableColumn.initData('deviceNumber', '设备', 150).setKeyValueList([
          { label: '设备:', labelWidth: 60, key: 'deviceNumber', attr: { canTooltip: true } },
          { label: 'IMEI号:', labelWidth: 60, key: 'imei', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('uploadTime', '出厂\n上传时间').setWidth(150).setSpan({}),
        BaseTableColumn.initData('deviceProviderName', '设备商名称').setSpan({}),
        BaseTableColumn.initData('h5ActiveStatus', '安装状态').setSpan({}),
        BaseTableColumn.initData('activeTime', '安装时间').setWidth(150).setSpan({}),
        BaseTableColumn.initData('propertyCompanyName', '安装所属\n物业公司名称').setSpan({})
      ]
    };
  }
};
