export class DeviceInitDataModel extends BaseModel {
  constructor () {
    super();
    // 设备ID
    this.deviceId = '';
    // 设备类别
    this.deviceCategory = '';
    this.deviceCategoryName = '';
    // 端口类别
    this.portCategory = '';
    this.portCategoryName = '';
    // 设备型号
    this.deviceModel = '';
    // 设备型号名称
    this.deviceName = '';
    this.deviceTypeName = '';
    // 物联网信息
    this.iotInfo = '';
    // 设备编码
    this.deviceNumber = '';
    // IMEI号
    this.imei = '';
    // 上传时间
    this.uploadTime = '';
    // 设备商ID
    this.deviceProviderId = '';
    // 设备商名称
    this.deviceProviderName = '';
    // 安装状态
    this.activeStatus = '';
    // 安装时间
    this.activeTime = '';
    // 物业公司ID
    this.propertyCompanyId = '';
    // 物业公司名称
    this.propertyCompanyName = '';
    // 生产厂商
    this.manufacturers = '';
    this.file = '';

    // 前端专用 ==========>
    // 安装状态
    this.h5ActiveStatus = '';
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this.deviceCategoryName = ((resData.deviceTypeList || []).find(v => v.value === resData.deviceCategory) || {}).label;
    this.portCategoryName = ((resData.chargingPileTypeList || []).find(v => v.value === resData.portCategory) || {}).label;
    this.h5ActiveStatus = resData.activeStatus === 0 ? '未安装' : '已安装';
    this._checkId = this.deviceId;
    return this;
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        id: '000101010' + i,
        tel: '13904288000',
        province: BaseModel.getFakeTitle(4) + '省',
        city: BaseModel.getFakeTitle(6) + '市',
        area: BaseModel.getFakeTitle(6) + '区',
        mainPrice: parseFloat((Math.random() * 99999).toFixed(2)),
        lightPrice: parseFloat((Math.random() * 30000).toFixed(2))
      };
      list.push(dic);
    }
    return list;
  }

  /**
   * 数据具体校验-新增
   * @returns {*}
   */
  checkValidSubmit () {
    return BaseModel.getValidRes(true, '', {
      deviceTypeId: this.deviceCategory,
      file: this.file
    });
  }

  static createAdditionModel () {
    return new this();
  }
}
