<!--
    Created by 王丽莎 on 2024/01/25.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：设备管理-设备出厂管理
-->
<style lang="less">
#device-init-list {
  .col-text-strong {
    color: @adornColor;
    font-weight: 600;
  }
}
</style>

<template>
  <div id="device-init-list">
    <!-- 查询条件 -->
    <div
      v-permission="'system:device:factory:list'"
    >
      <yg-search-area
        ref="YgSearchArea"
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:device:factory:list'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
      />
    </div>
    <!--弹窗:新增-->
    <v-submit
      v-if="!!dialog.submitModel"
      :model="dialog.submitModel"
      @on-close="dialog.submitModel = null"
      @on-sure="_clickSure"
    />
  </div>
</template>

<script>
import DeviceApiSet from '@/request/api/DeviceApiSet';
import { DeviceInitDataModel } from './model/DeviceInitDataModel';
import ColumnMixin from '@/pages/050__deviceManager/deviceInitList/mixin/tableColumn';
import vSubmit from './view/submit.vue';
import { mapActions } from 'vuex';

export default {
  components: { vSubmit },
  mixins: [ColumnMixin],
  data () {
    return {
      default_searchOption: {
        deviceProviderId: BaseSearchModel.initData('设备商名称', 'deviceProviderId', 'YgGlobalSelect').setData('deviceProvider'),
        activeStatus: BaseSearchModel.initData('安装状态', 'activeStatus', 'Select').setData([
          { value: '0', label: '未安装' },
          { value: '1', label: '已安装' }
        ]),
        deviceNoLabel: BaseSearchModel.initData('设备型号名称设备型', 'deviceNoLabel', 'Input').setLabelSelect([
          { value: 'deviceNumber', label: '设备编码' },
          { value: 'deviceModelName', label: '设备型号名称' },
          { value: 'imei', label: 'IMEI号' }
        ]),
        portCategory: BaseSearchModel.initData('端口类别', 'portCategory', 'YgGlobalSelect').setData('chargingPileType'),
        selectTimeLabel: BaseSearchModel.initData('时间', 'selectTimeLabel', 'DatePickerRange').setDatePickerElevator([]).setLabelSelect([
          { value: 'uploadStartTime-uploadEndTime', label: '出厂上传时间' },
          { value: 'activeStartTime-activeEndTime', label: '安装时间' }
        ])
      },
      fws_searchOption: {
        // deviceProviderId: BaseSearchModel.initData('设备商名称', 'deviceProviderId', 'YgGlobalSelect').setData('deviceProvider'),
        propertyCompanyId: BaseSearchModel.initData('所属物业公司', 'propertyCompanyId', 'YgSiteCascader').setData(5),
        deviceCategory: BaseSearchModel.initData('设备类别', 'deviceCategory', 'YgGlobalSelect').setData('deviceType'),
        portCategory: BaseSearchModel.initData('端口类别', 'portCategory', 'YgGlobalSelect').setData('chargingPileType'),
        activeStatus: BaseSearchModel.initData('安装状态', 'activeStatus', 'Select').setData([
          { value: '0', label: '未安装' },
          { value: '1', label: '已安装' }
        ]),
        deviceNoLabel: BaseSearchModel.initData('设备型号名称设备型', 'deviceNoLabel', 'Input').setLabelSelect([
          { value: 'deviceNumber', label: '设备编码' },
          { value: 'deviceModelName', label: '设备型号名称' },
          { value: 'imei', label: 'IMEI号' }
        ]),
        selectTimeLabel: BaseSearchModel.initData('时间', 'selectTimeLabel', 'DatePickerRange').setDatePickerElevator([]).setLabelSelect([
          { value: 'uploadStartTime-uploadEndTime', label: '出厂上传时间' },
          { value: 'activeStartTime-activeEndTime', label: '安装时间' }
        ])
      },
      settingExportIdList: [],
      settingExportCondition: {},
      settingItems: [
        BaseSettingModel.initData('导出列表', 'downloadList', '').setExport().setExportReportType((this.$storage.user.userType === this.$config.systemUserType.service ? 2 : 1)).setPermission('system:device:factory:export'),
        BaseSettingModel.initData('导出设备二维码', 'downloadQRCode', '').setExport().setExportReportType((this.$storage.user.userType === this.$config.systemUserType.service ? 5 : 4)).setPermission('system:device:qrcode:export'),
        BaseSettingModel.initData('新增出厂设备', 'addDevice', '').setPermission('system:device:factory:add')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        submitModel: null
      },
      deviceTypeList: [],
      chargingPileTypeList: []
    };
  },
  computed: {
    // 是否是服务商
    isServiceProvider () {
      return this.$storage.user.userType === this.$config.systemUserType.service;
    },
    // 搜索条件
    searchOption () {
      // 服务商
      if (this.$storage.user.userType === this.$config.systemUserType.service) {
        return this.fws_searchOption;
      }
      return this.default_searchOption;
    }
  },
  created () {
    console.log(this.isServiceProvider);
    this.$ygLoading.show();
    Promise.all([this.getDeviceTypeList(), this.getChargingPileTypeList()]).then(res => {
      this.deviceTypeList = res[0];
      this.chargingPileTypeList = res[1];
      this.$ygLoading.close();
    });
  },
  methods: {
    ...mapActions(['getDeviceTypeList', 'getChargingPileTypeList']),
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      console.log(option);
      this.page.current = 1;
      // this.searchOption = option;
      if (this.isServiceProvider) {
        this.fws_searchOption = option;
      } else {
        this.default_searchOption = option;
      }
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'addDevice':
        this.dialog.submitModel = DeviceInitDataModel.createAdditionModel();
        break;
      }
    },
    _clickSure () {
      console.log('导入成功');
      this.dialog.submitModel = null;
      this.requestData();
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      const s = this.searchOption;
      const api = DeviceApiSet.factoryRecordList;
      api.params = {
        activeStatus: s.activeStatus.value,
        portCategory: s.portCategory.value,
        pageNo: this.page.current,
        pageSize: this.$config.pageSize
      };
      if (this.isServiceProvider) {
        api.params.propertyCompanyId = s.propertyCompanyId.value[0] || '';
        api.params.deviceCategory = s.deviceCategory.value;
      } else {
        api.params.deviceProviderId = s.deviceProviderId.value;
      }
      // 筛选中可下拉的label
      api.params[s.deviceNoLabel.labelSelectValue] = s.deviceNoLabel.value;
      const timeKey = s.selectTimeLabel.labelSelectValue.split('-');
      api.params[timeKey[0]] = s.selectTimeLabel.value[0];
      api.params[timeKey[1]] = s.selectTimeLabel.value[1];
      this.settingExportCondition = api.params;
      this.tableData = [];
      this.page.total = 0;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        resData.rows.map(v => {
          v.deviceTypeList = this.deviceTypeList;
          v.chargingPileTypeList = this.chargingPileTypeList;
        });
        this.tableData = DeviceInitDataModel.initListWithDataList(resData.rows);
        DeviceInitDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
